.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .settings-page__header {
    margin-top: 24px;
  }
  
  .settings-page {
    min-height: 100vh;
    background-color: #f7f7f7;
  }
  
  #root {
    min-height: 100vh;
    background-color: #f7f7f7;
  }
  
  body {
    min-height: 100vh;
    background-color: #f7f7f7;
    font-family: Lato, sans-serif;
  }
  